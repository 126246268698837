import React from "react";

export default function Barra ({home}) {

    const backHome = () =>{
        home();
    }

    return (
        <nav className="navbar navbar-light navbar-expand-lg bg-info" >
            <div className="container-fluid">
            <a href="" className="navbar-brand text-light fw-bold ml-1" onClick={backHome} >Inicio</a>
                <div className="col text-center">
                    <a className="navbar-brand text-light fw-bold" >Grabadora de voz</a>
                </div>
                
            </div>
        </nav>
    );
};


