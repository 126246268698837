const API_URL = "https://grabaciondj.eie.ucr.ac.cr/api/records"

export const saveRecorder = async (newRecord) => {

    var blob = newRecord.file

    var reader = new FileReader();

    reader.readAsDataURL(blob); 
    reader.onloadend = async function() {
        var base64data = reader.result;
        var base64 = base64data.split(',')[1];                
        return enviar(base64);
    }

    const enviar = (blob64) =>{
        return fetch(API_URL,{
            method:'POST',
           headers:{
            'Content-Type':'applicaton/json'
            },
            mode:'no-cors',
            body:JSON.stringify({
                "fecha":newRecord.fecha,
                "categoria":String(newRecord.categoria).trim(),
                "frase":String(newRecord.frase).trim(),
                "file":blob64,
            })
        });
    } 

 
}
