import React from "react";
import logo from "./../../assets/imgs/logoucr.png";
import eielogo from "./../../assets/imgs/EIE-02.png";

const Header = () => {

    return (
        <nav className="navbar bg-light">
            <div className="container">
                <a className="navbar-brand" >
                    <img src={logo} className="rounded float-left" ></img>
                </a>
                <a className="navbar-brand" >
                <img src={eielogo} className="rounded float-right" width="500" height="150" ></img>
                </a>
            </div>
        </nav>
       
    );
};

export default Header;

