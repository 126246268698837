import React, { useState, useRef } from "react";
import * as RecorderServer from './recorderServer'
import micro from './../../assets/imgs/microfono.png'
import './style.css'



export default function Recorder ({categoria, onFinish, frases, largo}) {
  
    const[data] = useState({
      frase: frases,
    });

    const[count, setCount] = useState(0);

    const incrementCount = () => {
      setCount(count + 1);
    };

    

    const[record, setRecord] = useState();

    const [stream, setStream] = useState({
        access: false,
        recorder: null,
        error: ""
      });
    
      const [recording, setRecording] = useState({
        active: false,
        available: false,
        url: ""
      });
    
      const chunks = useRef([]);
    
      function getAccess() {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((mic) => {
            let mediaRecorder;
    
            try {
              mediaRecorder = new MediaRecorder(mic, {
                mimeType: "audio/webm"
              });
            } catch (err) {
              console.log(err);
            }
    
            const track = mediaRecorder.stream.getTracks()[0];
            track.onended = () => console.log("ended");
    
            mediaRecorder.onstart = function () {
              setRecording({
                active: true,
                available: false,
                url: ""
              });
            };
    
            mediaRecorder.ondataavailable = function (e) {
              chunks.current.push(e.data);
            };
    
            mediaRecorder.onstop = async function () {

              const blob = new File([chunks.current[0]],'voice.wav', { type: "audio/wav" });
              setRecord({...record, ['fecha']:Math.floor(Date.now() / 1000), ['categoria']:categoria, ['frase']:frases[count], ['file']:blob});
    
              const url = URL.createObjectURL(chunks.current[0]);
              chunks.current = [];
    
              setRecording({
                active: false,
                available: true,
                url
              });
            };

           
            setStream({
              ...stream,
              access: true,
              recorder: mediaRecorder
            });
          })
          .catch((error) => {
            console.log(error);
            setStream({ ...stream, error });
          });
      }

      const  handleRestart = async () =>{
        setRecording({
          active: false,
          available: false,
          url: ""
        });
        
        
      }


      const handleSave = async (e) =>{
        try{
          setRecording({
            active: false,
            available: false,
            url: ""
          });
          
          incrementCount();
            let res
            res = await RecorderServer.saveRecorder(record);
            //this.contador = this.contador+1;
            
            const data = await res.json();
            console.log(data)
        }
        catch(error){
            console.log(error)
        }
      }
      
        const handleFinish = async (e) =>{
          try{
              let res
              onFinish();
              res = await RecorderServer.saveRecorder(record);              
              const data = await res.json();
              console.log(data)
          }
          catch(error){
              console.log(error)
          }

      
    }
    
      return (
        <><br/><div className="row row-cols-1 row-cols-md-3 g-4 justify-content-center">
          <div className="col">
            <div class="card">
              <div class="card-body">
              {stream.access ? (          
                  <>
                    <div className="card h-100">
                    <div className="col text-center">Por favor grabe su voz diciendo "{data.frase[count]}"</div>
                      <br />
                      <img src={micro} class="rounded mx-auto d-block " width="80" height="80" alt="..."></img>
                      <br />
                      <div className="audio-container">
                      {recording.active && <p class="text-center text-bold">Grabando....</p>}
                        <div className="col text-center">
                          
                          {!recording.available && <button
                            type="button" className={recording.active ? "active btn btn-success m-1" : "btn btn-success m-1"}
                            onClick={() => !recording.active && stream.recorder.start()}
                          >
                            ◉ Grabar
                          </button>}
                          {!recording.available && <button type="button" className="btn btn-danger m-1" onClick={() => stream.recorder.stop()}>▣ Detener</button>}
                          {recording.available && <><audio controls src={recording.url} /><br /></>}
                          {recording.available && <button type="button" className="btn btn-primary m-1" onClick={handleRestart}>Volver a grabar</button>}
                          {recording.available && count < largo -1 && <button type="button" className="btn btn-primary m-1" onClick={handleSave}>Enviar y continuar</button>}
                          {recording.available && count === largo-1 && <button type="button" className="btn btn-primary m-1" onClick={handleFinish}>Enviar y finalizar</button>}

                        </div>
                        <br />
                      </div>
                    </div>
                  </>
               ) : (
                <div className="col text-center">
                <button className='btn btn-primary m-1' onClick={getAccess}>Obtener permiso de microfono</button>
                </div>
              )}



              </div>
            </div>
          </div></div><br /></>
      );

   
};

