import React from "react";

import Recorder from "./componentes/recorder/recorder";
import Header from './componentes/header/header';
import Tarjetas from './componentes/tarjetas/tarjetas';
import Pie from './componentes/pie/pie';
import Barra from './componentes/barra/barra';

class App extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            categoria: "",
            mode: "home",
            frases: []
        }
    }

    handleComponentChange = (categoria) =>{
        this.setState({categoria: categoria});
        this.setState({mode: "recorder"});
        var frases;
        if(categoria === "Desicion"){
             frases = ["Si","No"]
            this.setState({frases: frases});
        }
        if(categoria === "Emocion"){
             frases = ["Me siento feliz", "Me siento triste", "Tengo miedo", "Estoy molesto"]
            this.setState({frases: frases});
        }
    }

    setModeHome = () =>{
        this.setState({mode: "home"});
    }

    render(){
        const showRecorder = this.state.mode === "recorder"
        const showHome = this.state.mode === "home"
        
        return(
             <>
            <Header />
            <Barra home = {this.setModeHome} />
            <div>{showRecorder && <Recorder onFinish = {this.setModeHome} categoria = {this.state.categoria} frases = {this.state.frases} largo = {this.state.frases.length} />}</div>
            <div>{showHome && <Tarjetas onChangeComponent={this.handleComponentChange} />}</div>            
            <Pie />
            </>
        );
    }

};

export default App;