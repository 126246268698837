import React, { useState } from "react";


const Pie = () => {
    return (
        <footer className="page-footer font-small blue pt-4 bg-dark">
            <div className="container-fluid text-center text-md-left">
                <div className="row">
                    <div className="col-md-6 mt-md-0 mt-3">
                        <h5 className="text-uppercase">
                            <p class="text-white ">Información de contacto </p>
                        </h5>
                        <div className="text-white">
                            <ul>
                            <p>Ciudad de la Investigación, Universidad de Costa Rica</p>
                            <p> San Pedro, San José, Costa Rica</p>
                            <p>TEL: (+506) 2511-2600</p>
                            <p>FAX: (+506) 2511-3920</p>
                            </ul>
                        </div>

                    </div>

                    <hr className="clearfix w-100 d-md-none pb-0"/>

                    <div className="col-md-3 mb-md-0 mb-3">
                        <h5 className="text-uppercase text-white text-start">Enlaces útiles</h5>
                        <ul className="text-white">
                            <div className="text-start">
                                <li><a className="text-decoration-none " href="https://www.ucr.ac.cr/"> <p className="text-white  "> UNIVERSIDAD DE COSTA RICA </p></a></li>
                                <li><a className="text-decoration-none " href="https://eie.ucr.ac.cr/"> <p className="text-white "> ESCUELA DE INGENIERÍA ELÉCTRICA </p></a></li>
                                <li><a className="text-decoration-none " href="https://posgrado.eie.ucr.ac.cr/"> <p className="text-white "> POSGRADO EN INGENIERÍA ELéCTRICA </p></a></li>
                                <li><a className="text-decoration-none " href="https://accionsocial.ucr.ac.cr/"> <p className="text-white "> PORTAL ACCIÓN SOCIAL UCR </p></a></li>
                            </div>
                        </ul>
                    </div>

                </div>
            </div>

            <div className="footer-copyright text-center py-3">
                <p class="text-white"> © 2022 ESCUELA DE INGENIERÍA ELÉCTRICA, UNIVERSIDAD DE COSTA RICA.</p>
            </div>

        </footer>

    );


};

export default Pie;