
import React from "react";
import yesNo from "./../../assets/imgs/green-wave.png";
import emotions from "./../../assets/imgs/blue-wave.png";

class Tarjetas extends React.Component {

    onChangeComponent = (categoria) =>{
        this.props.onChangeComponent(categoria);
    }

    render(){
    return (
        <><br /><div className="row row-cols-1 row-cols-md-3 g-4 justify-content-center">
            <div className="col">
                <div className="card h-100">
                    <img src={yesNo} className="rounded mx-auto d-block p-1"  alt="..."></img>
                    <div className="card-body">
                        <h5 className="card-title">Toma de decisiones</h5>
                        <p className="card-text">En esta sección grabarás dos audios correspondientes a las palabras "sí" y "no".</p>
                        <button className="btn btn-primary" onClick={() => this.onChangeComponent("Desicion")}>Grabar toma de decisiones </button>
                    </div>
                </div>
            </div>
            <div className="col">
                <div className="card h-100">
                    <img src={emotions} className="rounded mx-auto d-block p-1"  alt="..."></img>
                    <div className="card-body">
                        <h5 className="card-title">Expresión de emociones</h5>
                        <p className="card-text">En esta sección grabarás cuatro audios expresando cuatro emociones distintas.</p>
                        <button className="btn btn-primary" onClick={() => this.onChangeComponent("Emocion")}>Grabar expresión de emociones</button>
                    </div>
                </div>
            </div>
        </div><br /></>
    );
    }

};

export default Tarjetas;